$(window).resize(function() {
  displayNav();
})

$(window).scroll(function() {
  sticky();
});

$('.hamburger').on('click', function() {
  if(!$(this).hasClass('hamburger--active')){
    $(this).addClass('hamburger--active');
    if(!$('.header').hasClass('sticky')){
      $('.header').addClass('sticky');
    }
    $('nav').slideDown(300);
  }else{
    $(this).removeClass('hamburger--active');
    sticky();
    $('nav').slideUp(300);
  }
});

$('.nav__link').on('click', function() {
  if($(window).outerWidth(true) < 1200) {
    $('.hamburger').removeClass('hamburger--active');
    $('nav').slideUp(300);
  }
});

function sticky() {
  if($(window).scrollTop() > 10){
    $('.header').addClass('sticky');
    $('[data-logo]').fadeOut();
    $('[data-logo-hover]').fadeIn(300);
  }else{
    $('.header').removeClass('sticky');
    $('[data-logo-hover]').fadeOut();
    $('[data-logo]').fadeIn(300);
  }
  if($('.hamburger').hasClass('hamburger--active')) {
    $('.header').addClass('sticky');
  }
}

function displayNav() {
  if($(window).outerWidth() > 1199) {
    $('nav').fadeIn();
  }
}
