ourWinesHeight();

$(window).resize(function() {
  ourWinesHeight();
});

function ourWinesHeight() {
  const height = $('#our-wines-text').height();
  $('.our-wines__bg').css('height', height);
}
